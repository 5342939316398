import React, { Fragment, useState } from 'react';
import { NewContext } from '../index';

const NewContextProvider = ({ children }) => {
    const [dataRender, setDataRender] = useState([]);

    const contextData = {
        dataRender,
        setDataRender,
    };

    return <NewContext.Provider value={contextData}>{children}</NewContext.Provider>;
};

export default NewContextProvider;
