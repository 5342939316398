import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '~/App.js';
import './assets/styles/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './components/GlobalStyles/GlobalStyles.js';

import HomeContextProvider from './services/hooks/contexts/providerContexts/HomeContextProvider';
import IntroduceContextProvider from './services/hooks/contexts/providerContexts/IntroduceContextProvider';
import ServiceContextProvider from './services/hooks/contexts/providerContexts/ServiceContextProvider';
import NewContextProvider from './services/hooks/contexts/providerContexts/NewContextProvider';
import RecruitmentContextProvider from './services/hooks/contexts/providerContexts/RecruitmentContextProvider';
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <GlobalStyles>
            <HomeContextProvider>
                <IntroduceContextProvider>
                    <ServiceContextProvider>
                        <NewContextProvider>
                            <RecruitmentContextProvider>
                                <App />
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                            </RecruitmentContextProvider>
                        </NewContextProvider>
                    </ServiceContextProvider>
                </IntroduceContextProvider>
            </HomeContextProvider>
        </GlobalStyles>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
