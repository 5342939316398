import React from 'react';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import config from '~/configs';
function PageNotFound() {
    return (
        <div className="container">
            <div className="content">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>
                    The Page you are looking for doesn't exist or an other error occured. Go to{' '}
                    <Link to={config.routes.home}>Home Page.</Link>
                </p>
            </div>
        </div>
    );
}
export default PageNotFound;
