import { PreLeaderIcon } from '~/assets/icons/icons';
import './NewsInternalHeading.scss';
import config from '~/configs';
import { Link } from 'react-router-dom';
function NewsInternalHeading({ title, titleChild, to }) {
    return (
        <div className="heading-news-internal">
            <div className="heading-container">
                <Link to={to}>
                    <PreLeaderIcon />
                    <span>{title}</span>
                </Link>
                <div></div>
                <p className='title'>{titleChild}</p>
            </div>
        </div>
    );
}

export default NewsInternalHeading;
