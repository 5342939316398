import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import './NavbarLayout.scss';
import images from '~/assets/images/Image';
import Menu, { MenuItem } from './Menu';
import config from '~/configs';
import { FacebookIcon, InstaIcon, HolaIcon, LogoImediaMobile, IconNavbarMobile } from '~/assets/icons/icons';
import MenuHover from '~/components/MenuHover/MenuHover';
import { navbarLayoutHeaderHeight } from '~/store/stores';
import { getDataHeader, getDataHeaderMenu } from '~/services/apis/layoutApis/headerApis';
import { fetchingData } from '~/utils/fetchingData';

function NavbarLayout() {
    // configure data

    const [scrollY, setScrollY] = useState(0);
    const [showNavbar, setShowNavBar] = useState(false);
    const navBarRef = useRef(null);

    // menu dropdown của service
    const menuItemDropdown = [
        {
            title: 'Hàng hoá số và hợp tác viễn thông',
            to: config.routes.serviceDetailDigital,
        },
        {
            title: 'Dịch vụ payment gateway',
            to: config.routes.serviceDetailPaymentGateway,
        },
        {
            title: 'Dịch vụ fintech',
            to: config.routes.serviceDetailFinTech,
        },
        {
            title: 'Vận chuyển',
            to: config.routes.serviceDetailShip,
        },
        {
            title: 'Hội nghị & sự kiện',
            to: config.routes.serviceDetailEvent,
        },
        {
            title: 'Cho thuê mặt bằng',
            to: config.routes.serviceDetailRent,
        },
    ];

    // menu link social
    const [dataSocial, setDataSocial] = useState([
        {
            link: config.routes.fbImedia,
            thumbnail: <FacebookIcon />,
        },
        {
            link: config.routes.instaImedia,
            thumbnail: <InstaIcon />,
        },
        {
            link: config.routes.hola,
            thumbnail: <HolaIcon />,
        },
    ]);

    // data service
    const [dataListService, setDataListService] = useState([
        {
            thumbnail: images.digitalcolor,
            link: config.routes.holaDigital,
            nameVn: '',
        },
        {
            thumbnail: images.shipcolor,
            link: config.routes.holaShip,
            nameVn: '',
        },
        {
            thumbnail: images.foodcolor,
            link: config.routes.holaShip,
            nameVn: '',
        },
        {
            thumbnail: images.freshcolor,
            link: config.routes.holaFresh,
            nameVn: '',
        },
    ]);

    // data logo
    const [dataLogo, setDataLogo] = useState({
        logoPrimary: images.logoImediaColor,
        logoSub: images.logoImediaScroll,
        logoMobile: null,
    });

    const [dataMenu, setDataMenu] = useState([
        {
            title: 'Trang chủ',
            to: config.routes.home,
        },
        {
            title: 'Giới thiệu',
            to: config.routes.introduce,
        },
        {
            title: 'Dịch vụ',
            to: config.routes.service,
            dropdownItems: menuItemDropdown,
        },
        {
            title: 'Tin tức',
            to: config.routes.news,
        },
        {
            title: 'Tuyển dụng',
            to: config.routes.recruitment,
        },
        {
            title: 'Liên hệ',
            to: config.routes.contact,
        },
    ]);

    // configure data

    // logical

    // Ẩn lớp "NavbarLayout-header" khi scrollY vượt qua navbarLayoutHeaderHeight
    const headerClass = useMemo(() => {
        return window.innerWidth > 1024 && scrollY > navbarLayoutHeaderHeight
            ? 'NavbarLayout-header-container hidden'
            : 'NavbarLayout-header-container';
    }, [window.innerWidth, scrollY, navbarLayoutHeaderHeight]);

    // Hiện Logo
    const logoImedia = useMemo(() => {
        return scrollY > navbarLayoutHeaderHeight ? 'logo-imedia-scroll block' : 'logo-imedia-scroll';
    }, [scrollY, navbarLayoutHeaderHeight]);

    const boxShadow = useMemo(() => {
        return scrollY > navbarLayoutHeaderHeight
            ? 'NavbarLayout-menu-container box-shadow'
            : 'NavbarLayout-menu-container';
    }, [scrollY, navbarLayoutHeaderHeight]);

    useEffect(() => {
        // Hàm xử lí sự kiện cuộn màn hình
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        // Lắng nghe sự kiện cuộn màn hình
        window.addEventListener('scroll', handleScroll);

        // Hủy lắng nghe sự kiện khi component bị unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollY]);

    useEffect(() => {
        // Lắng nghe sự kiện click trên toàn bộ trang
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    // logical

    // event handler

    const handleDocumentClick = (event) => {
        // Nếu sự kiện click xảy ra ngoài phần Navbar-mobile-icon và NavabrLayout-ListItem-Mobile
        if (
            navBarRef.current &&
            !navBarRef.current.contains(event.target) &&
            !event.target.classList.contains('NavabrLayout-ListItem-Mobile')
        ) {
            setShowNavBar(false);
        }
    };

    // xử lý hiện Navbar khi ấn vào menu-icon
    const handleShowNavbar = () => {
        setShowNavBar(!showNavbar);
    };

    // event handler

    // call apis

    const handleGetDataHeader = async () => {
        await fetchingData(async () => {
            const result = await getDataHeader();
            if (result.status === 200) {
                if (result.data.partner) {
                    setDataListService(result.data.partner);
                }

                if (result.data.logo || result.data.logoSub || result.data.logoMobile) {
                    let logoData = { ...dataLogo };
                    if (result.data.logo.thumbnail) {
                        logoData = { ...logoData, logoPrimary: result.data.logo.thumbnail };
                    }

                    if (result.data.logoSub.thumbnail) {
                        logoData = { ...logoData, logoSub: result.data.logoSub.thumbnail };
                    }

                    if (result.data.logoMobile.thumbnail) {
                        logoData = { ...logoData, logoMobile: result.data.logoMobile.thumbnail };
                    }

                    setDataLogo((prev) => {
                        return { ...prev, ...logoData };
                    });
                }

                if (result.data.externalLinks) {
                    setDataSocial(result.data.externalLinks);
                }
            }
        });
    };

    const handleDataHeaderMenu = async () => {
        await fetchingData(async () => {
            const result = await getDataHeaderMenu({
                slugParent: 'menu-top',
            });
            if (result.status === 200) {
                const dataRenderMenu = result.data.map((item) => {
                    let dropdownItems = null;
                    if (item.dataChild.length > 0) {
                        dropdownItems = item.dataChild.map((child) => {
                            return {
                                title: child.name,
                                to: child.slug,
                                dropdownItemsChild: child.dataChild.map((childItem) => {
                                    return {
                                        title: childItem.name,
                                        to: childItem.slug,
                                    };
                                }),
                            };
                        });
                    }
                    return {
                        title: item.name,
                        to: item.slug,
                        dropdownItems: dropdownItems,
                    };
                });
                setDataMenu(dataRenderMenu);
            }
        });
    };

    // call apis

    useEffect(() => {
        handleGetDataHeader();
        handleDataHeaderMenu();
    }, []);

    return (
        <div className="NavbarLayout">
            <div className={headerClass}>
                <div className="NavbarLayout-header">
                    <div className="NavbarLayout-logo">
                        <Link to={config.routes.home}>
                            <img src={dataLogo.logoPrimary} alt="logo" />
                        </Link>
                    </div>
                    <div className="NavbarLayout-logo-imedia">
                        {dataLogo.logoMobile ? (
                            <Link to={config.routes.home}>
                                <img src={dataLogo.logoMobile} alt="logo" />
                            </Link>
                        ) : (
                            <Link to={config.routes.home}>{<LogoImediaMobile />}</Link>
                        )}
                    </div>
                    <div className="NavbarLayout-HolaItems">
                        {dataListService &&
                            dataListService.map((data, index) => (
                                <a key={index} className="menulogo" href={data.link} target="_blank">
                                    <img src={data.thumbnail} alt={data.nameVn} />
                                </a>
                            ))}
                    </div>
                    <div className="Navbar-mobile-icon" onClick={handleShowNavbar} ref={navBarRef}>
                        <IconNavbarMobile />
                        {showNavbar && <MenuHover dataMenu={dataMenu} />}
                    </div>
                </div>
            </div>
            <div className={boxShadow}>
                <div className="NavbarLayout-menu">
                    <Menu>
                        <div className={logoImedia}>
                            <Link to={config.routes.home}>
                                <img src={dataLogo.logoSub} alt="" />
                            </Link>
                        </div>
                        <div className="NavabrLayout-ListItem">
                            {dataMenu &&
                                dataMenu.map((data, index) => (
                                    <MenuItem
                                        key={index}
                                        title={data.title}
                                        dropdownItems={data.dropdownItems}
                                        to={data.to}
                                    />
                                ))}
                        </div>
                        <div className="NavbarLayout-ListItem-MeidaSocial">
                            {dataSocial &&
                                dataSocial.map((data, index) => (
                                    <Link key={index} to={data.link} target="_blank">
                                        <div className="icon-link-social">
                                            {React.isValidElement(data.thumbnail) ? (
                                                data.thumbnail
                                            ) : (
                                                <ReactSVG src={data.thumbnail} />
                                            )}
                                        </div>
                                    </Link>
                                ))}
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default NavbarLayout;
