import NavbarLayout from './NavbarLayout/NavbarLayout';
import FooterLayout from './FooterLayout/FooterLayout';
import './DefaultLayout.scss';
import NewsInternalHeading from '~/components/NewsInternalHeading/NewsInternalHeading';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IconHeaderPage } from '~/assets/icons/icons';
import LazyLoading from '~/components/UseLazyLoad/UseLazyLoad';
import ChatBot from '~/components/ChatBot/ChatBot';
function DefaultLayout({ children, componentChild }) {
    const [loadDing, setLoadDing] = useState(true);
    const [dataSubMenu, setDataSubMenu] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const handleIconClick = () => {
        // Xử lý khi icon-header được click
        // Cuộn đến đầu trang với hiệu ứng mượt
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (componentChild) {
            setDataSubMenu({
                to: componentChild && componentChild.to,
                title: componentChild && componentChild.title,
                titleChild: componentChild && componentChild.titleChild,
            });
        } else {
            setDataSubMenu(null);
        }
    }, [componentChild]);

    return (
        <div className="content-imedia">
            {loadDing && <LazyLoading />}
            <Fragment>
                {false && (
                    <div className="icon-header" onClick={handleIconClick}>
                        <IconHeaderPage />
                    </div>
                )}

                {true && <ChatBot />}
                <NavbarLayout />
                {dataSubMenu && (
                    <NewsInternalHeading
                        to={dataSubMenu.to}
                        title={dataSubMenu.title}
                        titleChild={dataSubMenu.titleChild}
                    />
                )}
                <div className="Container-body">
                    {React.Children.map(children, (child) => {
                        // Thêm props vào mỗi child
                        return React.cloneElement(child, {
                            loadDing: loadDing,
                            setLoadDing: setLoadDing,
                            setDataSubMenu: setDataSubMenu,
                        });
                    })}
                </div>
                <FooterLayout />
            </Fragment>
        </div>
    );
}

export default DefaultLayout;
