import Hotline from './Hotline';
import './Chatbot.scss';
import ChatZalo from './ChatZalo';

const ChatBot = ({}) => {
    return (
        <>
            <div className="social-list">
                <ChatZalo />
                <Hotline />
            </div>
        </>
    );
};
export default ChatBot;
