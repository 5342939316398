import React, { Fragment, useState } from 'react';
import { RecruitmentContext } from '../index';

const RecruitmentContextProvider = ({ children }) => {
    const [dataRender, setDataRender] = useState([]);

    const contextData = {
        dataRender,
        setDataRender,
    };

    return <RecruitmentContext.Provider value={contextData}>{children}</RecruitmentContext.Provider>;
};

export default RecruitmentContextProvider;
