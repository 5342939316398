import React, { useEffect } from 'react';

import images from '~/assets/images/Image';
import './UseLazyLoad.scss';
import LoadingSpinner from '../LoadingPrinner/LoadingSpinner';
function LazyLoading() {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        // Cleanup function to enable scrolling again when the component unmounts ok
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="lazy-loading-Container">
            <div className="lazy-loading position-relative">
                <LoadingSpinner type="FadeLoader" size={50} />
            </div>
        </div>
    );
}

export default LazyLoading;
