import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes } from './routes';
import DefaultLayout from './layouts/DefaultLayout/DefaultLayout';
import React, { Suspense } from 'react';
import images from './assets/images/Image';
import LazyLoading from './components/UseLazyLoad/UseLazyLoad';
import PageNotFound from './components/PageNotFound/PageNotFound';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Layout = route.layout || DefaultLayout;
                        const Page = route.component;
                        const componentChild = route.componentChild;
                        return (
                            <Route
                                key={route.path}
                                path={route.path}
                                exact
                                element={
                                    <Suspense fallback={<LazyLoading />}>
                                        <Layout componentChild={componentChild}>
                                            <Page />
                                        </Layout>
                                    </Suspense>
                                }
                            >
                                {route.childrenRouter &&
                                    route.childrenRouter.map((child, indexChild) => (
                                        <Route
                                            exact
                                            key={child.path}
                                            path={child.path}
                                            element={<Suspense fallback={<LazyLoading />}>{child.component}</Suspense>}
                                        />
                                    ))}
                            </Route>
                        );
                    })}
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
