import React from 'react';
import { DotLoader, FadeLoader, RingLoader } from 'react-spinners';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ type, size }) => {
    return (
        <div className="loading-spinner-container">
            {type === 'FadeLoader' && (
                <FadeLoader
                    color="#415ba9"
                    height={20}
                    margin={10}
                    radius={10}
                    width={10}
                    speedMultiplier={2}
                    cssOverride={{ marginTop: '100px' }}
                    size={size || 60}
                />
            )}
            {type === 'RingLoader' && (
                <RingLoader
                    speedMultiplier={2}
                    size={size || 60}
                    color="#415ba9"
                    cssOverride={{ marginTop: '100px' }}
                />
            )}

            {!type && <DotLoader size={size || 60} color="#415ba9" cssOverride={{ marginTop: '100px' }} />}
        </div>
    );
};

export default LoadingSpinner;
