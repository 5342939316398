import { ReactSVG } from 'react-svg';
import React, { Fragment, useEffect, useState } from 'react';

import images from '~/assets/images/Image';
import './FooterLayout.scss';
import { FacebookIcon, HolaIcon, InstaIcon } from '~/assets/icons/icons';
import { Link } from 'react-router-dom';
import config from '~/configs';

import { getDataFooter, getDataFooterMenu } from '~/services/apis/layoutApis/footerApis';
import { fetchingData } from '~/utils/fetchingData';

function FooterLayout() {
    // configure data

    const [dataFooter, setDataFooter] = useState({
        logo: images.logoImediaColor,
        infoCompany: {
            name: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ VÀ DỊCH VỤ IMEDIA',
            description:
                'Giấy chứng nhận ĐKKD số 0105837941 do Sở kế hoạch đầu tư Thành phố Hà Nội cấp ngày 27 tháng 03 năm 2012',
            detail: [
                {
                    address:
                        'Tầng 5, Tòa nhà iMedia Tower - 508 Trường Chinh, Phường Ngã Tư Sở, Quận Đống Đa, Thành phố Hà Nội, Việt Nam',
                    email: 'info@imediatech.com.vn',
                    phone: '(+84) 24 6295 8884',
                    isHeadquarter: 1,
                },
                {
                    address:
                        'Tầng 5, Tòa nhà iMedia Tower - 508 Trường Chinh, Phường Ngã Tư Sở, Quận Đống Đa, Thành phố Hà Nội, Việt Nam',
                    email: 'info@imediatech.com.vn',
                    phone: '(+84) 24 6295 8884',
                    isHeadquarter: 0,
                },
            ],
        },
    });
    const [dataMenuFooter, setDataMenuFooter] = useState({
        title: 'CHÍNH SÁCH',
        dataChill: [
            {
                title: 'Bảo mật',
                to: config.routes.policySecurity,
            },
            {
                title: 'Thanh toán',
                to: config.routes.policyPayMent,
            },
            {
                title: 'Vận chuyển',
                to: config.routes.policyShip,
            },
            {
                title: 'Hoàn phí',
                to: config.routes.policyRefunds,
            },
            {
                title: 'Bồi thường',
                to: config.routes.policyIndemnify,
            },
        ],
    });

    // menu link social
    const [dataSocial, setDataSocial] = useState([
        {
            link: config.routes.fbImedia,
            thumbnail: <FacebookIcon />,
        },
        {
            link: config.routes.instaImedia,
            thumbnail: <InstaIcon />,
        },
        {
            link: config.routes.hola,
            thumbnail: <HolaIcon />,
        },
    ]);

    // configure data

    // call api

    const handleGetDataFooter = async () => {
        await fetchingData(async () => {
            const result = await getDataFooter();
            if (result.status === 200) {
                if (result.data.logoCompany || result.data.infoCompany) {
                    let newDataFooter = { ...dataFooter };
                    if (result.data.logoCompany) {
                        newDataFooter = { ...newDataFooter, logo: result.data.logoCompany.thumbnail };
                    }
                    if (result.data.infoCompany) {
                        newDataFooter = { ...newDataFooter, infoCompany: result.data.infoCompany };
                    }

                    if (result.data.externalLinks) {
                        setDataSocial(result.data.externalLinks);
                    }

                    setDataFooter((prev) => {
                        return { ...prev, ...newDataFooter };
                    });
                }
            }
        });
    };

    const handleDataFooterMenu = async () => {
        await fetchingData(async () => {
            const result = await getDataFooterMenu({
                slugParent: 'menu-footer',
            });
            if (result.status === 200) {
                const dataRenderMenu = result.data[0].dataChild.map((item) => {
                    return {
                        title: item.name,
                        to: item.slug,
                    };
                });
                setDataMenuFooter((prev) => {
                    return {
                        ...prev,
                        title: result.data[0].name,
                        dataChill: dataRenderMenu,
                    };
                });
            }
        });
    };

    // call api

    // run

    useEffect(() => {
        handleGetDataFooter();
        handleDataFooterMenu();
    }, []);

    // run

    return (
        <div className="footer-container-content">
            <div className="footer-content">
                <div className="logo-and-title">
                    <div className="logo-footer">
                        <img src={dataFooter.logo} alt="" />
                    </div>
                    <p className="title-footer">{dataFooter.infoCompany.name}</p>
                </div>
                <div className="content-container">
                    <div className="content-des">
                        <div className="description">
                            {/* <span>C:</span> */}
                            <p>{dataFooter.infoCompany.description}</p>
                        </div>

                        {dataFooter.infoCompany.detail.map((item, index) => {
                            if (Number(item.isHeadquarter) === Number(1)) {
                                return (
                                    <Fragment key={index}>
                                        <div className="description mt-1rem">
                                            <span className="address-text">TRỤ SỞ CHÍNH</span>
                                        </div>
                                        <div className="description">
                                            <span>A:</span>
                                            <p>{item.address}</p>
                                        </div>
                                        <div className="description">
                                            <span>T:</span>
                                            <p>{item.phone}</p>
                                            <span className="email">E:</span>
                                            <p>{item.email}</p>
                                        </div>
                                    </Fragment>
                                );
                            }

                            if (Number(item.isHeadquarter) === Number(0)) {
                                return (
                                    <Fragment key={index}>
                                        <div className="description mt-1rem">
                                            <span className="address-text">CHI NHÁNH</span>
                                        </div>
                                        <div className="description">
                                            <span>A:</span>
                                            <p>{item.address}</p>
                                        </div>
                                        <div className="description">
                                            <span>T:</span>
                                            <p>{item.phone}</p>
                                            <span className="email">E:</span>
                                            <p>{item.email}</p>
                                        </div>
                                    </Fragment>
                                );
                            }

                            return null;
                        })}
                    </div>
                    <div className="content-policy">
                        <div>
                            <p>{dataMenuFooter.title}</p>
                            <ul className="list-policy">
                                {dataMenuFooter.dataChill.map((item, index) => {
                                    return (
                                        <Link key={index} to={item.to} className="mt-1rem display-block">
                                            <li>{item.title}</li>
                                        </Link>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="content-app">
                        <div className="app-container">
                            <p>Tải app hệ sinh thái hola</p>
                            <div className="list-app">
                                <div className="download-imedia">
                                    <div>
                                        <Link to="https://apps.apple.com/vn/app/hola/id1600810029?l=vi" target="_blank">
                                            <img src={images.appstore} alt="" />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link
                                            to="https://play.google.com/store/apps/details?id=vn.vmg.hola.appshop"
                                            target="_blank"
                                        >
                                            <img src={images.ggplay} alt="" />
                                        </Link>
                                    </div>
                                </div>

                                <a
                                    href="http://online.gov.vn/Home/WebDetails/83349"
                                    target="_blank"
                                    className="logo-notice cursor-pointer"
                                >
                                    <img src={images.nociteImage} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-footer">
                <div className="footer-bottom-container">
                    <div className="description">
                        Copyright © 2023 <span>Công ty cổ phần công nghệ và dịch vụ </span> iMedia
                    </div>
                    <div className="Footer-ListItem-MeidaSocial">
                        {dataSocial &&
                            dataSocial.map((data, index) => (
                                <Link key={index} to={data.link} target="_blank">
                                    <div className="icon-link-social">
                                        {React.isValidElement(data.thumbnail) ? (
                                            data.thumbnail
                                        ) : (
                                            <ReactSVG src={data.thumbnail} />
                                        )}
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterLayout;
