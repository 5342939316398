import React, { Fragment, useState } from 'react';
import { HomeContext } from '../index';

const HomeContextProvider = ({ children }) => {
    const [dataRender, setDataRender] = useState([]);

    const contextData = { dataRender, setDataRender };

    return <HomeContext.Provider value={contextData}>{children}</HomeContext.Provider>;
};

export default HomeContextProvider;
