import images from '~/assets/images/Image';
import config from '~/configs';

function ChatZalo() {
    return (
        <div className="social-item ">
            <div className="social-item-content"></div>
            <div className="social-item-icon zalo">
                <a href={config.routes.zalo} target="_blank">
                    <img src={images.zalo_icon} alt="zalo-icon" width="60" height="60" />
                </a>
            </div>
        </div>
    );
}

export default ChatZalo;
