import './Menu.scss'
function Menu({ children, className }) {

    const classes = `navbar-container ${className}`;
    return <nav className={classes}>
        {children}
        </nav>;
}

export default Menu;
