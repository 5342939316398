const images = {
    logoImedia: require('./ImediaLogo.png'),
    logoImediaScroll: require('./ImediaScroll.png'),
    logoImediaColor: require('./LogoImediaColor.png'),
    HolaFresh: require('./HolaFresh.png'),
    HolaFood: require('./HolaFood.png'),
    HolaShip: require('./HolaShip.png'),
    HolaDigital: require('./HolaDigital.png'),
    instagramSocial: require('./insta.png'),
    holaSocial: require('./hola.png'),
    appstore: require('./appstore.png'),
    ggplay: require('./ggplay.png'),
    nociteImage: require('./notice.png'),
    freshcolor: require('./freshcolor.png'),
    foodcolor: require('./foodcolor.png'),
    shipcolor: require('./shipcolor.png'),
    digitalcolor: require('./digitalcolor.png'),
    BG_gt: require('./BG_gt.png'),
    zalo_icon: require('./zalo-icon.png'),
};
export default images;
