import axios from '../axios';

const getDataFooter = async (header = {}) => {
    return await axios({
        url: '/footer',
        method: 'GET',
        headers: header,
    });
};

const getDataFooterMenu = async (data, header = {}) => {
    return await axios({
        url: '/menu/list',
        method: 'GET',
        params: data,
        headers: header,
    });
};

export { getDataFooter, getDataFooterMenu };
