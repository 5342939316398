function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
        return '';
    }
    // Chia số điện thoại thành các phần
    const part1 = String(phoneNumber).slice(0, 4);
    const part2 = String(phoneNumber).slice(4, 7);
    const part3 = String(phoneNumber).slice(7);

    // Ghép các phần lại với khoảng trắng giữa các phần
    const formattedNumber = `${part1} ${part2} ${part3}`;

    return formattedNumber;
}

export { formatPhoneNumber };
