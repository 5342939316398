import { MenuItem } from '~/layouts/DefaultLayout/NavbarLayout/Menu';
import './MenuHover.scss';
import config from '~/configs';
function MenuHover({dataMenu, className}) {

const classes = `NavabrLayout-ListItem-Mobile ${className}`
    return (
        <div className={classes}>
            {dataMenu.map((item, index)=>(
                <MenuItem mobile key={index} tabItem={true} childTab={item.childTab} title={item.title} to={item.to} />
            ))}
        </div>
    );
}

export default MenuHover;
