import { PhoneIcon } from '~/assets/icons/icons';
import { formatPhoneNumber } from '~/utils/format';

const Hotline = () => {
    return (
        <div className="social-item mt-2rem">
            <div className="social-item-content">
                <span className="phone-text">{formatPhoneNumber(process.env.REACT_APP_ZALO_PHONE)}</span>
            </div>
            <div className="social-item-icon phone">
                <a href={`tel:${process.env.REACT_APP_ZALO_PHONE}`}>
                    <PhoneIcon />
                </a>
            </div>
        </div>
    );
};

export default Hotline;
