const codeComponents = {
    MAIN_BANNER: 'MAIN_BANNER',
    LATEST_NEWS: 'LATEST_NEWS',
    LIST_RECRUITMENT: 'LIST_RECRUITMENT',
    LIST_SERVICE_BASE: 'LIST_SERVICE_BASE',
    OTHER_SERVICE: 'OTHER_SERVICE',
    LIST_NEWS_BASE: 'LIST_NEWS_BASE',
    PARTNER: 'PARTNER',
    TITLE_DESCRIPTION: 'TITLE_DESCRIPTION',
    LIST_MILESTONES: 'LIST_MILESTONES',
    OVERVIEW: 'OVERVIEW',
    ORIENTATION: 'ORIENTATION',
    LEADER: 'LEADER',
    ROOM: 'ROOM',
    TWO_SLIDE: 'TWO_SLIDE',
    LIST_SERVICE_ITEM: 'LIST_SERVICE_ITEM',
    INTRODUCTION_SERVICE: 'INTRODUCTION_SERVICE',
    OFFERING_SERVICES: 'OFFERING_SERVICES',
    RADIO: 'RADIO',
    LIST_NEWS_ITEM: 'LIST_NEWS_ITEM',
    LEFT_DETAIL_NEW_ITEM: 'LEFT_DETAIL_NEW_ITEM',
    RIGHT_DETAIL_NEW_ITEM: 'RIGHT_DETAIL_NEW_ITEM',
    LIST_CENTER_RECRUITMENT: 'LIST_CENTER_RECRUITMENT',
    LIST_INDUSTRY_GROUP: 'LIST_INDUSTRY_GROUP',
    LEFT_DETAIL_RECRUITMENT_ITEM: 'LEFT_DETAIL_RECRUITMENT_ITEM',
    RIGHT_DETAIL_RECRUITMENT_ITEM: 'RIGHT_DETAIL_RECRUITMENT_ITEM',
    FORM_CONTRACT: 'FORM_CONTRACT',
    CATALOGUE_SERVICE: 'CATALOGUE_SERVICE',
    DESCRIPTION_CONFERENCE: 'DESCRIPTION_CONFERENCE',
    DESCRIPTION_BUILDING: 'DESCRIPTION_BUILDING ',
    IMAGE_BUILDING: 'IMAGE_BUILDING ',
    FACILITIES_BUILDING: 'FACILITIES_BUILDING ',
    ADDRESS_BUILDING: 'ADDRESS_BUILDING',
    FORM_CONFERENCE: 'FORM_CONFERENCE',
    RECRUITMENT_CATEGORY: 'RECRUITMENT_CATEGORY',

    SERVICEDEFAULT: 'ServiceDefault',
    SERVICESEMINAR: 'ServiceSeminar',
    SERVICEBUILDING: 'ServiceBuilding',
};

export default codeComponents;
