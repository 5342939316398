import React, { Fragment, useState } from 'react';
import { ServiceContext } from '../index';

const ServiceContextProvider = ({ children }) => {
    const [dataRender, setDataRender] = useState([]);
    const [dataListService, setDataListService] = useState([]);

    const contextData = {
        dataRender,
        setDataRender,
        dataListService,
        setDataListService,
    };

    return <ServiceContext.Provider value={contextData}>{children}</ServiceContext.Provider>;
};

export default ServiceContextProvider;
