import React, { Fragment, useState } from 'react';
import { IntroduceContext } from '../index';

const IntroduceContextProvider = ({ children }) => {
    const [dataRender, setDataRender] = useState([]);
    const [dataOverviewImediaGroup, setDataOverviewImediaGroup] = useState([]);
    const [dataVisionAndMission, setDataVisionAndMission] = useState([]);

    const contextData = {
        dataRender,
        setDataRender,
        dataOverviewImediaGroup,
        setDataOverviewImediaGroup,
        dataVisionAndMission,
        setDataVisionAndMission,
    };

    return <IntroduceContext.Provider value={contextData}>{children}</IntroduceContext.Provider>;
};

export default IntroduceContextProvider;
