import axios from '../axios';

const getDataHeader = async (header = {}) => {
    return await axios({
        url: '/header',
        method: 'GET',
        headers: header,
    });
};

const getDataHeaderMenu = async (data, header = {}) => {
    return await axios({
        url: '/menu/list',
        method: 'GET',
        params: data,
        headers: header,
    });
};

export { getDataHeader, getDataHeaderMenu };
