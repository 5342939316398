import { Link, NavLink } from 'react-router-dom';
import './Menu.scss';
import { useState } from 'react';
import { DropdownMenu } from '~/assets/icons/icons';
function MenuItem({ title, to, dropdownItems, mobile, childTab, tabItem }) {
    const [showDropBar, setShowDropBar] = useState(false);
    const handleHover = () => {
        setShowDropBar(true);
    };

    const handleLeave = () => {
        setShowDropBar(false);
    };

    const handleClick = () => {
        setShowDropBar(false);
    };
    return (
        <NavLink
            className={`menu-items social ${
                tabItem ? (childTab ? 'menu-item-child active-custom active' : 'menu-item-child') : ''
            } `}
            to={to}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
        >
            {dropdownItems && (
                <span className="title dropdown">
                    {title}
                    {showDropBar && (
                        <ul className="dropdown-parent" onClick={handleClick}>
                            {dropdownItems.map((item, index) => (
                                <NavLink key={index} to={item.to}>
                                    <li className="menu-dropdown-child">
                                        {item.title}
                                        {item.dropdownItemsChild && (
                                            <ul onClick={handleClick}>
                                                {item.dropdownItemsChild.map((item, index) => (
                                                    <NavLink key={index} to={item.to}>
                                                        <li>{item.title}</li>
                                                    </NavLink>
                                                ))}
                                            </ul>
                                        )}
                                        {item.dropdownItemsChild && item.dropdownItemsChild.length > 0 && (
                                            <DropdownMenu className="dropdown-menu-icon" />
                                        )}
                                    </li>
                                </NavLink>
                            ))}
                        </ul>
                    )}
                </span>
            )}
            {!dropdownItems && <span className="title">{title}</span>}
            {mobile && <div className="line-mobile"></div>}
        </NavLink>
    );
}
export default MenuItem;
