import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_URL_API_BASE,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Cache-Control': 'max-age=3600',
    },
    timeout: process.env.REACT_APP_TIMEOUT_API,
});

instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return error.response && error.response.data;
    },
);

export default instance;
