import config from '~/configs';
import React from 'react';
const LazyHome = React.lazy(() => import('~/pages/Home/Home'));
const LazyIntroduce = React.lazy(() => import('~/pages/Introduce/Introduce'));
const LazyService = React.lazy(() => import('~/pages/Service/Service'));
const LazyServiceDetail = React.lazy(() => import('~/pages/Service/ServiceDetail'));
const LazyServiceParents = React.lazy(() => import('~/pages/Service/ServiceParents/HolaLand/HolaLand'));
const LazyNews = React.lazy(() => import('~/pages/News/News'));
const LazyRecruitment = React.lazy(() => import('~/pages/Recruitment/Recruitment'));
const LazyContact = React.lazy(() => import('~/pages/Contact/Contact'));
const LazyCategoryNews = React.lazy(() => import('~/components/CategoryNews/CategoryNews'));
const CategoryRecruitment = React.lazy(() => import('~/components/CategoryRecruitment/CategoryRecruitment'));
const LazyNewsDetails = React.lazy(() => import('~/pages/News/NewsDetails/NewsDetails'));
const LazyRecruitmentBusiness = React.lazy(() => import('~/pages/Recruitment/RecruitmentBusiness/RecruitmentBusiness'));
const LazySecurity = React.lazy(() => import('~/pages/Policy/Security/Secutity'));

const publicRoutes = [
    // link đến trang chính sách

    // trang chủ
    { path: config.routes.home, component: LazyHome },

    // giới thiệu
    { path: config.routes.introduce, component: LazyIntroduce },

    // dịch bụ
    { path: config.routes.service, component: LazyService },

    // dịch vụ cha
    { path: `${config.routes.service}/:slug`, component: LazyServiceParents },

    // chi tiết dịch vụ
    { path: `${config.routes.serviceDetail}/:slug`, component: LazyServiceDetail },

    // tin tức
    {
        path: config.routes.news,
        component: LazyNews,
        childrenRouter: [
            { path: config.routes.news, component: <LazyCategoryNews />, init: true },
            {
                path: ':slug',
                component: (
                    <div>
                        <LazyCategoryNews />
                    </div>
                ),
            },
        ],
    },

    // chi tiết tin tức
    {
        path: `${config.routes.newsDetailInternal}/:slug`,
        component: LazyNewsDetails,
        componentChild: { title: 'tin tức', titleChild: 'nội bộ', to: config.routes.news },
    },

    // tuyển dụng
    {
        path: config.routes.recruitment,
        component: LazyRecruitment,
        childrenRouter: [
            { path: config.routes.recruitment, component: <CategoryRecruitment />, init: true },
            {
                path: ':slug',
                component: <CategoryRecruitment />,
            },
        ],
    },

    // chi tiết tuyển dụng
    {
        path: `${config.routes.recruitmentBusiness}/:slug`,
        component: LazyRecruitmentBusiness,
        componentChild: {
            title: 'tuyển dụng',
            titleChild: ' tuyển nhân viên kinh doanh',
            to: config.routes.recruitment,
        },
    },

    // liên hệ
    { path: config.routes.contact, component: LazyContact },

    // // // chính sách
    // {
    //     path: config.routes.policy + '/:slug',
    //     component: LazySecurity,
    //     componentChild: { title: 'chính sách', titleChild: 'bảo mật', to: config.routes.home },
    // },

    // chính sách
    {
        path: config.routes.policy + '/:slug',
        component: LazyNewsDetails,
        componentChild: { title: 'chính sách', titleChild: 'bảo mật', to: config.routes.home },
    },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
