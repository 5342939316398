const routes = {
    home: '/', // trang chủ
    introduce: '/gioi-thieu', // giới thiệu
    service: '/dich-vu', // dịch vụ
    serviceDetail: '/dich-vu/chi-tiet', // dịch vụ chi tiết
    serviceDigital: '/dich-vu/tong-quan', // dịch vụ digital
    serviceDetailDigital: '/dich-vu/tong-quan/hang-hoa-so-va-hop-tac-vien-thong', // hàng hóa số và hợp tác viễn thông
    serviceDetailFinTech: '/dich-vu/tong-quan/fintech', // dịch vụ fin tech
    serviceDetailPaymentGateway: '/dich-vu/tong-quan/payment-gateway', // dịch vụ payment gateway
    serviceDetailShip: '/dich-vu/tong-quan/van-chuyen', // dịch vụ vận chuyển
    serviceDetailEvent: '/dich-vu/hoi-nghi-va-su-kien', // dịch vụ nghị sự kiện
    serviceDetailRent: '/dich-vu/cho-thue-mat-bang', // cho thuê mặt bằng
    news: '/tin-tuc', // tin tức
    newsDetailInternal: '/tin-tuc/chi-tiet', // chi tiết tin tức
    recruitment: '/tuyen-dung', // tuyển dụng
    recruitmentBusiness: '/tuyen-dung/chi-tiet', // chi tiết tuyển dụng
    contact: '/lien-he', // lien he

    // Link to chính sách
    policy: '/chinh-sach',
    policySecurity: '/chinh-sach/bao-mat', // chính sách bảo mật
    policyPayMent: '/chinh-sach/thanh-toan', // chính sách thanh toán
    policyShip: '/chinh-sach/van-chuyen', // chính sách vận chuyển
    policyRefunds: '/chinh-sach/hoan-phi', // chính sách hoàn phí
    policyIndemnify: '/chinh-sach/boi-thuong', // chính sách bồi thường

    // Link to outsite
    fbImedia: 'https://www.facebook.com/iMediaTechnologyCompany',
    instaImedia:
        'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFVOTvmZxOsXQAAAYq9QqyQxDDcPMGU_ayvto7Xc7GprPr1RtWpMpcFmURwG-0cfhbO4rH3goKXCEXlxnFwF9PC65TGsa9FxeX4SvIwGSuAKzAXd4NieyNok4rDblhoPpcct4k=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fimedia-jsc',
    hola: 'https://id.holalife.vn/vi',
    holaDigital: 'https://digital.holalife.vn/',
    holaShip: 'https://ship.holalife.vn/vi',
    holaFood: 'https://food.holalife.vn/',
    holaFresh: 'https://fresh.holalife.vn/',
    zalo: 'https://zalo.me/0902193266',
};
export default routes;
